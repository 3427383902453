import React from 'react'

function ContactSection() {
  return (
    <div>
      <div className="flex justify-start items-center w-full mb-10 p-8 md:p-16 lg:px-24">
        <div className="flex items-center w-[30%] md:w-[20%]">
          <div className="shrink-0 w-6 md:w-12 h-6 md:h-12 rounded-full bg-gray-200"></div>
          <div className="w-full h-[2px] bg-gray-200"></div>
        </div>
        <h4 className="shrink mx-8 text-xl md:text-2xl text-gray-300 font-bold">
          Contacts
        </h4>
      </div>
      <div className="relative grid grid-cols-1 md:grid-cols-2 h-auto lg:h-[60vh] bg-gray-200">
        <div>
          <div className="p-8 md:p-16 lg:p-24 text-start">
            <h2 className="mb-3 text-2xl md:text-3xl font-semibold">
              Vous avez besoin de nos services
            </h2>
            <p className="mb-8">
              Contactez nous en précisant l'objet de votre demande et nous vous répondrons dans les plus bref délais
            </p>
            <div className="flex gap-x-5">
              <div className="flex justify-center items-center w-12 h-12 bg-sky-600"><img className='w-[50%] h-[50%]' src={require('../../img/Linkedin.png')} alt="" /></div>
              <div className="flex justify-center items-center w-12 h-12 bg-sky-600"><img className='w-[50%] h-[50%]' src={require('../../img/Twitter.png')} alt="" /></div>
              <div className="flex justify-center items-center w-12 h-12 bg-sky-600"><img className='w-[50%] h-[50%]' src={require('../../img/Facebook.png')} alt="" /></div>
              <div className="flex justify-center items-center w-12 h-12 bg-sky-600"><img className='w-[50%] h-[50%]' src={require('../../img/Whatsapp.png')} alt="" /></div>
            </div>
          </div>
        </div>
        <div className="relative">
          <div className="md:absolute md:inset-x-0 md:top-[-10vh] md:bottom-0 md:pr-16 lg:pr-24">
            <div className="w-full lg:h-[70vh] p-5 md:p-10 bg-white shadow-xl">
              <div className="mb-5 text-start">
                <p className="mb-2">Nom</p>
                <input className="w-full p-3 bg-gray-100" />
              </div>
              <div className="mb-5 text-start">
                <p className="mb-2">Email</p>
                <input className="w-full p-3 bg-gray-100" />
              </div>
              <div className="mb-5 text-start">
                <p className="mb-2">Commentaires</p>
                <textarea
                  cols={10}
                  rows={10}
                  className="w-full p-3 bg-gray-100"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactSection