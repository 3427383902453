import React from "react";

function AboutSection() {
  return (
    <div className="px-8 md:px-16 lg:px-24 pb-10" id="about">
      <div className="flex justify-center items-center w-full mb-10">
        <div className="flex items-center w-[30%]">
          <div className="shrink-0 w-6 md:w-12 h-6 md:h-12 rounded-full bg-gray-200"></div>
          <div className="w-full h-[2px] bg-gray-200"></div>
        </div>
        <h4 className="shrink mx-8 text-xl md:text-2xl text-gray-300 font-bold">
          À propos
        </h4>
        <div className="flex items-center w-[30%]">
          <div className="w-full h-[2px] bg-gray-200"></div>
          <div className="shrink-0 w-6 md:w-12 h-6 md:h-12 rounded-full bg-gray-200"></div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 w-full">
        <div className="flex items-center">
          <img
            className="w-full md:w-[35vw] mb-5 md:mb-0"
            src={require("../../img/cadre.png")}
          />
          {/* <div className="w-[35vw] h-[35vw] rounded-lg">
          </div> */}
        </div>
        <div className="flex flex-col justify-center">
          <h1 className="mb-3 text-2xl md:text-3xl lg:text-4xl font-bold text-slate-700 text-center md:text-end">
            Notre agence
          </h1>
          <p className="mb-5 text-lg text-slate-400 text-justify">
            Nous sommes une entreprise de conseils et d'accompagnement dans le
            développement des projets d'entreprise. Nous souhaitons
            encourager les idées de projet d'entreprise. Nous sommes passionnés par
            notre travail et nous nous engageons à fournir des services de
            qualité supérieure à nos clients. Nous sommes fiers de notre équipe
            d'experts en affaire qui ont une expérience approfondie dans divers
            domaines. Nous sommes là pour vous aider à réussir.
          </p>
          <div>
            <div className="mb-1 px-3 md:px-6 py-2 md:py-4 bg-gray-200 text-start md:text-end">
              <p className="text-sm md:text-lg text-gray-700 font-semibold">
                Elaboration des strategies marketing et commerciales
              </p>
            </div>
            <div className="mb-1 px-3 md:px-6 py-2 md:py-4 bg-gray-200 text-start md:text-end">
              <p className="text-sm md:text-lg text-gray-700 font-semibold">
                Analyse de la profitabilité d'un nouveau projet
              </p>
            </div>
            <div className="mb-1 px-3 md:px-6 py-2 md:py-4 bg-gray-200 text-start md:text-end">
              <p className="text-sm md:text-lg text-gray-700 font-semibold">
                Assistance aux entreprises
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutSection;
