import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './authentication/login';
import HomePage from './pages';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path='/' Component={HomePage} />
          <Route path='/login' Component={Login} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
