import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../constantes";

function MobileNavBarMenu(props) {
  return (
    <div className="fixed inset-0 m-5 px-10 py-5 rounded-lg bg-white shadow-lg">
      <div className="flex justify-between items-start mb-8">
        <Link className="px-4 py-2 bg-sky-600 text-white font-semibold">
          Se connecter
        </Link>
        <button className="text-xl" onClick={props.handleShowMenu}>
          x
        </button>
      </div>
      <ul className="flex flex-col items-start gap-x-8">
        {ROUTES.map((route) => (
          <Link to={route.path} className="w-full mb-3 text-gray-800 font-semibold text-start cursor-pointer">
            <p className="mb-2">{route.name}</p>
            <hr className="w-full border border-gray-100" />
          </Link>
        ))}
      </ul>
    </div>
  );
}

export default MobileNavBarMenu;
