import React from 'react'

function PubSection() {
  return (
    <div className="grid grid-cols-3 w-full">
      <div></div>
    </div>
  )
}

export default PubSection