export default [
    {
        path: "/",
        name: "Accueil"
    },
    {
        path: "#about",
        name: "A propos"
    },
    {
        path: "#services",
        name: "Services"
    },
    {
        path: "#programme",
        name: "Programmes"
    },
    {
        path: "#contact",
        name: "Contact"
    }
]