import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "../constantes";
import MobileNavBarMenu from "./mobileNavBarMenu";

function Navbar() {
  const [showMenu, setShowMenu] = useState(false);

  function handleShowMenu() {
    setShowMenu((prevShowMenu) => !prevShowMenu);
  }

  const location = useLocation()

  function locationStyle(route) {
    const style = location.pathname === route.path ? "border-sky-600 text-sky-600" : ""
    return style
  }

  return (
    <div className="relative z-50 flex justify-between items-center px-4 md:px-16 lg:px-24 py-5">
      <h2 className="text-xl md:text-2xl font-bold">LaCentraleInc</h2>
      <ul className="hidden md:flex items-center gap-x-8">
        {ROUTES.map((route) => (
          <Link to={route.path} className={`border-b-2 border-transparent hover:border-sky-600 text-gray-800 hover:text-sky-600 font-semibold cursor-pointer duration-300 ${locationStyle(route)}`}>{route.name}</Link>
        ))}
      </ul>
      <Link
        to="/login"
        className="hidden md:block px-5 py-2 bg-sky-600 shadow-xl text-white text-sm md:text-base font-semibold cursor-pointer"
      >
        Se connecter
      </Link>
      <button className="md:hidden px-2 py-1 border border-gray-100 rounded text-gray-700 font-semibold" onClick={handleShowMenu}>
        Menu
      </button>
      {showMenu && <MobileNavBarMenu handleShowMenu={handleShowMenu} />}
    </div>
  );
}

export default Navbar;
