import React from 'react'

function Header() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 w-full mb-10 md:mb-0 xl:mb-[20vw] px-8 md:px-16 lg:px-24">
      <div className="text-center md:text-start flex flex-col justify-center items-start">
        <h1 className="mb-3 md:pr-24 text-2xl md:text-3xl lg:text-4xl lg:text-5xl font-bold text-slate-700">
          Lacentraleinc
        </h1>
        <span className='ml-8 font-italic'>Le portail de l'entrepreneuriat</span>
        <p className="mt-5 mb-8 md:pr-32 text-sm md:text-base lg:text-lg text-slate-400 text-justify">
          Faites-vous conseiller et accompagner dans la réalisation de vos projets d'entreprise
        </p>
        <button className="mx-auto md:mx-0 px-8 md:px-16 py-3 md:rounded bg-sky-600 shadow md:shadow-lg text-white font-semibold text-sm md:text-base">
          Commencer
        </button>
      </div>
      <div className="hidden md:block relative md:h-[450px] lg:h-[60vh]">
        <div className="z-10 absolute md:top-[-60%] lg:top-[-30vw] md:right -[-50%] lg:right-[-60%] w-[75vw] h-[75vw] rounded-full">
          <img className="w-full h-full" src={require("../img/header.png")} />
        </div>
        {/* <div className="absolute -top-20 z-20 w-full h-12 bg-gray-200"></div> */}
      </div>
    </div>
  )
}

export default Header