import React from "react";

function ProgramSection() {
  return (
    <div className="px-8 md:px-16 lg:px-24 py-10">
      <div className="flex justify-center items-center w-full mb-10">
        <div className="flex items-center w-[30%]">
          <div className="shrink-0 w-6 md:w-12 h-6 md:h-12 rounded-full bg-gray-200"></div>
          <div className="w-full h-[2px] bg-gray-200"></div>
        </div>
        <h4 className="shrink mx-8 text-xl md:text-2xl text-gray-300 font-bold">
          Programmes
        </h4>
        <div className="flex items-center w-[30%]">
          <div className="w-full h-[2px] bg-gray-200"></div>
          <div className="shrink-0 w-6 md:w-12 h-6 md:h-12 rounded-full bg-gray-200"></div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="mb-5 md:mb-0">
          {/* <div className="w-[40vw] h-[40vw] rounded-full bg-sky-600"></div> */}
          <img
            className="w-full md:w-[90%]"
            src={require("../../img/circles_img.png")}
          />
        </div>
        <div className="flex flex-col justify-center">
          <h3 className="mb-5 text-slate-800 text-xl md:text-3xl font-semibold text-justify">
            Un processus simple et efficace
          </h3>
          <ul>
            <li className="flex items-start pb-7">
              <div className="shrink-0 flex justify-center items-center w-8 md:w-16 h-8 md:h-16 rounded-full bg-sky-600 text-white text-sm md:text-xl font-semibold">
                1
              </div>
              <div className="pl-5">
                <h3 className="text-xl font-semibold text-start">
                  Contactez nous
                </h3>
                <p className="text-justify">
                  Nous sommes a votre disposition 24h/24 afin de prendre en
                  compte votre demande
                </p>
              </div>
            </li>
            <li className="flex items-start pb-7">
              <div className="shrink-0 flex justify-center items-center w-8 md:w-16 h-8 md:h-16 rounded-full bg-sky-600 text-white text-sm md:text-xl font-semibold">
                2
              </div>
              <div className="pl-5">
                <h3 className="text-xl font-semibold text-start">
                  Consultation
                </h3>
                <p className="text-justify">
                  Nous effectuons une étude et une analyse de votre demande
                </p>
              </div>
            </li>
            <li className="flex items-start pb-7">
              <div className="shrink-0 flex justify-center items-center w-8 md:w-16 h-8 md:h-16 rounded-full bg-sky-600 text-white text-sm md:text-xl font-semibold">
                3
              </div>
              <div className="pl-5">
                <h3 className="text-xl font-semibold text-start">
                  Accompagnement
                </h3>
                <p className="text-justify">
                  Vous serez guidez par nos expert jusqu'à l'aboutissement de votre projet
                </p>
              </div>
            </li>
            <li className="flex items-start pb-7">
              <div className="shrink-0 flex justify-center items-center w-8 md:w-16 h-8 md:h-16 rounded-full bg-sky-600 text-white text-sm md:text-xl font-semibold">
                4
              </div>
              <div className="pl-5">
                <h3 className="text-xl font-semibold text-start">
                  Paiement
                </h3>
                <p className="text-justify">
                  Reglez les honoraires liés a votre demande en toute simplicité
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ProgramSection;
