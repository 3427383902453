import React from 'react'

function ServicesSection() {
  return (
    <div className="px-8 md:px-16 lg:px-24 py-10" id="services">
      <div className="flex justify-center items-center w-full mb-10">
        <div className="flex items-center w-[30%]">
          <div className="shrink-0 w-6 md:w-12 h-6 md:h-12 rounded-full bg-gray-200"></div>
          <div className="w-full h-[2px] bg-gray-200"></div>
        </div>
        <h4 className="shrink mx-8 text-xl md:text-2xl text-gray-300 font-bold">
          Services
        </h4>
        <div className="flex items-center w-[30%]">
          <div className="w-full h-[2px] bg-gray-200"></div>
          <div className="shrink-0 w-6 md:w-12 h-6 md:h-12 rounded-full bg-gray-200"></div>
        </div>
      </div>
      <div className="relative grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 mb-5 md:mb-[20vh]">
        <div className="absolute left-[10vw] bottom-[-20vh] ">
          <div className="w-[50vw] h-[40vh]">
            <img className="object-cover" src={require("../../img/pattern.png")} />
          </div>
        </div>
        <div>
          <div className="w-full h-[50%] rounded-lg bg-gray-200"></div>
        </div>
        <div className="relative flex flex-col h-[350px] p-5 bg-white shadow-xl text-start overflow-hidden">
          <h4 className="block mb-5 text-xl font-semibold">Evaluation des risques et opportunités</h4>
          <p className="block mb-auto text-gray-600 text-justify">
            Nous nous chargeons d'effectuer une analyse poussée des risques et opportunités liés à votre idée de projet afin de le rendre le plus sûr fiable possible
          </p>

          <div className="block mt-auto mb-10">
            <button className="mt-auto font-semibold">En savoir plus</button>
          </div>
          <div className="absolute right-[-2rem] bottom-[-2rem] w-[5rem] h-[5rem] rounded-full bg-sky-600"></div>
        </div>
        <div className="relative flex flex-col h-[350px] p-5 bg-white shadow-xl text-start overflow-hidden">
          <h4 className="block mb-5 text-xl font-semibold">Conseils et accompagnement</h4>
          <p className="block mb-auto text-gray-600 text-justify">
            Nous mettons à votre disposition des professionnels qualité qui vous aiderons à faire evolué votre entreprise
          </p>

          <div className="block mt-auto mb-10">
            <button className="mt-auto font-semibold">En savoir plus</button>
          </div>
          <div className="absolute right-[-2rem] bottom-[-2rem] w-[5rem] h-[5rem] rounded-full bg-sky-600"></div>
        </div>
        <div className="relative flex flex-col h-[350px] p-5 bg-white shadow-xl text-start overflow-hidden">
          <h4 className="block mb-5 text-xl font-semibold">Amélioration de la rentabilité</h4>
          <p className="block mb-auto text-gray-600 text-justify">
            Des études poussés sont faits par nos soins dans vos divers domaines et secteurs d'activité dans le but d'ameliorer votre chiffre d'affaire et d'une vue plus globale votre rentabilité
          </p>

          <div className="block mt-auto mb-10">
            <button className="mt-auto font-semibold">En savoir plus</button>
          </div>
          <div className="absolute right-[-2rem] bottom-[-2rem] w-[5rem] h-[5rem] rounded-full bg-sky-600"></div>
        </div>
      </div>
    </div>
  )
}

export default ServicesSection