import React from 'react'

function ArticleSection() {
  return (
    <div className="relative h-[50vh] lg:h-[80vh] mb-64 md:mb-32 lg:mb-48 bg-gray-200">
      <div className="absolute inset-x-0 bottom-[-10vh] px-8 md:px-16 lg:px-24">
        <div className="grid grid-cols-1 md:grid-cols-2 h-[50vh] lg:h-[80vh] shadow-2xl">
          <div className="p-8 md:p-16 lg:p-24 bg-white text-start">
            <h2 className="mb-3 text-xl md:text-2xl lg:text-3xl font-semibold">
              Vous désirez faire évoluer votre entreprise ?
            </h2>
            <p className="text-justify md:text-start">
              lacentraleinc est une agence possédant en son sein de nombreux expert et cadre compétents qui sont à votre disposition quelle que soit votre préoccupations.
            </p>
          </div>
          <div className="flex items-end p-8 md:p-16 lg:p-24 bg-cover" style={{ backgroundImage: `linear-gradient(to bottom, #3176f5dd, #3176f5dd), url(${require("../../img/OIP.jpeg")})` }}>
            <h2 className="mb-3 text-white text-xl md:text-2xl lg:text-3xl font-semibold text-end">
              "Nous sommes là pour vous aider à réussir"
            </h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArticleSection