import React from 'react'

function DescriptionSection() {
  return (
    <div className="mx-auto p-8 md:p-16 lg:px-24">
      <p className="text-slate-800 text-xl md:text-2xl lg:text-3xl font-bold text-justify md:text-center">
        Nous sommes là pour vous aider à réussir en vous fournissant des conseils et des solutions personnalisés pour votre projet d'entreprise
      </p>
      <div className="w-8 md:w-16 h-8 md:h-16 mx-auto my-5 rounded-full bg-sky-600"></div>
    </div>
  )
}

export default DescriptionSection