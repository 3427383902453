import React, { useEffect, useState } from "react"
// import bgImg from '../../assets/img/1.jpg'

export default function Login () {

    const [login, setLogin] = useState("")
    const [password, setPassword] = useState("")

    const [authState, setAuthState] = useState("");

    async function authenticate() {
        // await fetch(`http://localhost:3004/users?phone=${login}&password=${password}`)
        //     .then(response => response.json())
        //     .then(data => setAuthState(data))
    }

    // useEffect(() => {
    //     if(authState != "") {
    //         console.log(authState)
    //         if(authState != null) {
    //             if(authState[0].role == 1) {
    //                 window.location.replace('http://localhost:3000/home-employer')
    //             } else {
    //                 window.location.replace('http://localhost:3000/home-employee')
    //             }
    //         } else {
    //             alert("Erreur de connexion")
    //         }
    //     }
    // }, [authState])

    return (
        // <div className="fixed inset-0 flex justify-center items-center bg-black/80 bg-cover" style={{backgroundImage: `linear-gradient(to bottom, #000000ee, #000000ee))`}}>
        <div className="fixed inset-0 flex justify-center items-center bg-black/80 bg-cover" style={{backgroundImage: `linear-gradient(to bottom, #000000dd, #000000dd), url(${require("../img/OIP.jpeg")})`}}>
            <div className="w-[35vw]">
                <h2 className="mb-5 text-white text-4xl text-center font-bold">CONNEXION</h2>
                <div className="p-10 rounded-lg bg-white text-start shadow-xl">
                    <div className="mb-5">
                        <p className="mb-2">Email ou numero de telephone</p>
                        <input type="text" className="w-full px-3 py-3 rounded-lg bg-gray-200" value={login} onChange={(e) => (setLogin(e.target.value))} />
                    </div>
                    <div className="mb-3">
                        <p className="mb-2">Mot de passe</p>
                        <input type="password" className="w-full px-3 py-3 rounded-lg bg-gray-200" value={password} onChange={(e) => (setPassword(e.target.value))} />
                    </div>
                    <div className="flex justify-end mb-5">
                        <a href="" className="text-end ">Mot de passe oublie</a>
                    </div>
                    <div className="flex gap-x-2 mb-3">
                        <input type="checkbox" name="" id="" />
                        <p>Se souvenir de moi</p>
                    </div>
                    <button className="w-full py-3 rounded-lg bg-blue-500 text-white text-lg font-bold shadow-lg" onClick={() => authenticate()}>Se connecter</button>
                    <div className="flex justify-center gap-x-2 mt-3">
                        <p>Vous n'etes pas encore inscrit ?</p>
                        <a href="" className="text-blue-500">Je m'inscris</a>
                    </div>
                </div>
            </div>
        </div>
    )
}