import React from "react";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Navbar from "../layouts/navbar";
import AboutSection from "./homePageSection/aboutSection";
import ArticleSection from "./homePageSection/articleSection";
import ContactSection from "./homePageSection/contactSection";
import DescriptionSection from "./homePageSection/descriptionSection";
import ProgramSection from "./homePageSection/programSection";
import PubSection from "./homePageSection/pubSection";
import ServicesSection from "./homePageSection/servicesSection";

function HomePage() {
  return (
    <div className=" overflow-hidden">
      <Navbar />
      <Header />
      <PubSection />
      <AboutSection />
      <ServicesSection />
      <ProgramSection />
      <ArticleSection />
      <DescriptionSection />
      <ContactSection />
      <Footer />
    </div>
  );
}

export default HomePage;
